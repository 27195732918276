import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { arraySymmetricalDifference } from '../utils/array-utils';
import { isDeepEqual } from '../utils/object-utils';

type PushHistoryFunc = (url: string, paramsSame: boolean) => void;

const setQueryStringWithoutPageReload = (pathname: string, qsValue: string, paramsSame: boolean, pushHistory: PushHistoryFunc) => {
  const newurl = `${pathname}${qsValue}`;
  pushHistory(newurl, paramsSame);
};

// Chose high limit for array
const queryStringOptions = { arrayLimit: 150 };

const convertNullOrUndefinedToEmptyObject = (val1: any) => {
  if (val1 === null || val1 === undefined) {
    return {};
  }
  return val1;
};

const paramsTheSame = (val1: any, val2: any) => {
  return isDeepEqual(convertNullOrUndefinedToEmptyObject(val1), convertNullOrUndefinedToEmptyObject(val2));
};

const setQueryStringValue = <T>(
  key: string,
  value: T,
  queryString = window.location.search,
  pathname: string,
  pushHistory: PushHistoryFunc,
) => {
  const values = qs.parse(queryString.replace('?', ''), queryStringOptions);
  const valuesObject = { ...values, [key]: value };
  if (value === null || value === undefined) {
    delete valuesObject[key];
  }
  const newQsValue = qs.stringify(valuesObject);
  setQueryStringWithoutPageReload(pathname, `?${newQsValue}`, paramsTheSame(valuesObject[key], values[key]), pushHistory);
};

const getQueryStringValue = (key: string, queryString = window.location.search) => {
  const values = qs.parse(queryString.replace('?', ''), queryStringOptions);
  return values[key];
};

export type UseQueryStringValueSetter = (newVal: any, pushUrl?: PushHistoryFunc, currentURL?: string) => void;

export function defaultParseFunction<T>(val: ReturnType<typeof getQueryStringValue>) {
  return val as any as T;
}

export function useQueryString<T>(
  key: string,
  defaultValue: T,
  initialQueryParam = false,
  parseFunction: (value: ReturnType<typeof getQueryStringValue>) => T = defaultParseFunction,
): [T, UseQueryStringValueSetter] {
  const initialValue: T = getQueryStringValue(key) ? parseFunction(getQueryStringValue(key)) : defaultValue;
  const [value, setValue] = useState<T>(initialValue);
  const valueRef = useRef<T>(initialValue);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const newValue = getQueryStringValue(key, location.search) as string;
    const parsedValue = parseFunction(newValue) ?? (defaultValue as any);

    const prevState = valueRef.current;

    if (prevState !== parsedValue) {
      if (
        Array.isArray(prevState) &&
        Array.isArray(parsedValue) &&
        arraySymmetricalDifference(prevState, parsedValue).length === 0
      ) {
        // DO NOTHING
      } else {
        valueRef.current = parsedValue;
        setValue(parsedValue);
      }
    }
  }, [navigate, location, key, defaultValue, parseFunction]);

  const onSetValue = useCallback(
    (newValue: T, pushUrl?: PushHistoryFunc, currentURL: string = window.location.search) => {
      setQueryStringValue<T>(key, newValue, currentURL, location.pathname, (url, paramsSame) => {
        if (pushUrl) {
          pushUrl(url, paramsSame);
        } else if (paramsSame !== true) {
          navigate(url, { replace: true });
        }
      });
    },
    [navigate, key, location.pathname],
  );

  if (initialQueryParam) {
    setQueryStringValue(key, value as any, window.location.search, location.pathname, (url) => navigate(url, { replace: true }));
  }

  return [value, onSetValue];
}
