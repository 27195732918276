export enum StringAppSearchParam {
  MAKE = 'make',
  MODEL = 'model',
  BODY = 'body',
  FUEL = 'fuel',
  GEAR = 'gear',
  ADVICE = 'advice',
  LOCATION_NAME = 'location_name',
  STATUS = 'status',
  SUPPLY_TYPE = 'type_of_supply',
  LICENSE_PLATE = 'license_plate',
  SWITCH_TO_DEALER = 'switch_to_dealer',
  DATA = 'data',
  DATA_TYPE = 'data_type',
  SCOPE = 'scope',
  SEARCH_KEY = 'search_key',
  SEARCH_VALUE = 'search_value',
  USER_EMAIL = 'user_email',
  DESCRIPTION = 'description',
  ORDER = 'order',
  BUILD_DATE = 'build_date',
  TOKEN = 'token',
  DEALER = 'dealer',

  // Added As a test for the filtering of CSM
  TOTAL_USAGE_CHANGE = 'total_usage_change',
  TOTAL_CALLS_CHANGE = 'total_calls_change',
  TOTAL_USAGE_0_30 = 'total_usage_0_to_30',
  ARR_CATEGORY = 'arr_category',
  AUTO_ACCOUNT_HEALTH = 'auto_account_health',
  HEALTH = 'account_health',
  END_DATE_CONTRACT = 'end_date_contract',
  CSM = 'csm',
  LAST_COMMENT_DATE = 'last_comment_date',
  COMMENTS = 'comments',
}
